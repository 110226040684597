import PropTypes from "prop-types";
import { forwardRef, useEffect, useState, useRef } from "react";
import MediaCarouselItem from "app/pages/Merchandising/Media/MediaCarouselItem";
import { resolutionPropType } from "app/utils/propTypes";
import "./MediaCarousel.scss";
import Swiper from "swiper";
import get from "lodash/get";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { sendTagOnMediaClickDesktop, sendTagOnMediaClickMobile } from "app/utils/analytics";
import IconPrevious from "app/pages/.shared/static/icons/IconPrevious";
import IconNext from "app/pages/.shared/static/icons/IconNext";

const MediaCarousel = forwardRef(
	({ mediaList, resolution, setSelectedMediaTitle = () => {} }, ref) => {
		const [activeIndex, setActiveIndex] = useState(0);
		const [isBeginning, setBeginning] = useState(true);
		const [isEnd, setIsEnd] = useState(false);
		const swiperRef = useRef(null);
		const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

		useEffect(() => {
			if (ref?.current) {
				// documentation of swiper 4 : https://github.com/nolimits4web/Swiper/blob/v4/API.md
				swiperRef.current = new Swiper(ref.current, {
					speed: 700,
					spaceBetween: isMobile ? 0 : 6,
					slidesPerView: isMobile ? 1.23 : 1.5,
					centeredSlides: true,
					preventInteractionOnTransition: !isMobile,
					slideToClickedSlide: true,
					threshold: 5, // https://github.com/nolimits4web/swiper/issues/3167#issuecomment-520947900
					shortSwipes: isMobile, // https://github.com/nolimits4web/swiper/issues/3167#issuecomment-520947900
					navigation: {
						nextEl: ".media-carousel__button--next",
						prevEl: ".media-carousel__button--previous",
					},
					on: {
						slideChange: function() {
							const newIndex = this.realIndex;
							setBeginning(this.isBeginning);
							setIsEnd(this.isEnd);
							setActiveIndex(newIndex);
							const newTitle = mediaList[newIndex % mediaList.length].title;
							setSelectedMediaTitle(newTitle);

							if (isMobile) {
								sendTagOnMediaClickMobile();
							} else {
								sendTagOnMediaClickDesktop(newTitle);
							}
						},
					},
				});

				return () => {
					swiperRef.current.destroy();
				};
			}

			return null;
		}, [mediaList, isMobile, ref?.current]);

		return (
			<div className="media-carousel__caroussel">
				<div ref={ref} className="swiper-container media-carousel">
					{!isMobile && (
						<div className="media-carousel__swiper">
							{!isBeginning && (
								<>
									<div className="media-carousel__nav media-carousel__nav--previous" />
									<IconPrevious
										className="media-carousel__button media-carousel__button--previous"
										data-testid="carousel-previous-button"
									/>
								</>
							)}
							{!isEnd && (
								<>
									<div className="media-carousel__nav media-carousel__nav--next" />
									<IconNext
										className="media-carousel__button media-carousel__button--next"
										data-testid="carousel-next-button"
									/>
								</>
							)}
						</div>
					)}
					<div className="swiper-wrapper">
						{mediaList.map((mediaItem, index) => (
							<div className="swiper-slide" key={mediaItem.title}>
								<MediaCarouselItem
									isActive={activeIndex === index}
									videoPreviewUrl={get(mediaItem, "video.previewUrl")}
									photoUrl={mediaItem.image}
									photoThumbnail={mediaItem.thumbnail}
									title={mediaItem.title}
									resolution={resolution}
									author={mediaItem.copyright}
									videoType={mediaItem?.video?.videoType}
									videoUrl={mediaItem?.video?.url}
									videoThumbnail={mediaItem?.video?.thumbnail}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
);

MediaCarousel.propTypes = {
	mediaList: PropTypes.arrayOf(PropTypes.object),
	resolution: resolutionPropType,
	setSelectedMediaTitle: PropTypes.func,
};

export default MediaCarousel;
